@import '../colors.scss';

.footer {
    background: $corporate-footer-bg-color;
    color: $corporate-txt-color-alt;
    min-height: 200px;

    .footer-section .h5 {
        border-bottom: 1px solid $corporate-txt-color-alt;
        margin-bottom: 12px;
    }

    a,
    .h5 {
        color: $corporate-txt-color-alt;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    .phone-link-open {
        text-decoration: none;
    }

    .association-logo {
        max-height: 45px;
    }
}