.phone-link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.phone-icon {
    margin-right: 3px;
    margin-top: 4px;
}