@import "../colors.scss";

$caption-opacity: 0.8;
$shadow-length: 0 0 0.5em #333;

.dfc-main-carousel {
    overflow: hidden;
    height: 100vh;

    .carousel-inner,
    .carousel-item {
        height: 100vh;
        background: no-repeat center center scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        // img {
        //     height: 100vh;
        // }
    }

    .carousel-caption {
        top: 50%;
        right: 50%;
        left: auto;
        transform: translate(50%, -50%);
        color: $corporate-txt-color-alt;
        text-shadow: 3px 3px 3px $text-color;
        width: 80%;
        max-width: 80%;

        h5 {
            // background-color: rgba(0,0,0,0.4);
            color: $corporate-txt-color-alt;
            font-size: 2.2rem !important;
            font-family: 'Open Sans', sans-serif;
            font-weight: 200;
            padding: 12px;
        }
    }

    .multiple-shadows {
        text-shadow: $shadow-length, $shadow-length, $shadow-length;
    }

    .carousel-see-more {
        text-align: center;
        position: absolute;
        right: 0;
        bottom: 18px;
        left: 0;
        z-index: 15;
        margin-right: 15%;
        margin-left: 15%;
        color: $corporate-txt-color-alt;
        text-shadow: 1px 1px 1px $text-color;

        a {
            color: $corporate-txt-color-alt;
            text-decoration: none;
        }
    }
}

@media (max-width: 575.98px) {
    .dfc-main-carousel {
        height: 15vh;
        .carousel-item {
            height: 15vh;
        }
    }
}