@import '../../../common/colors.scss';

.services-card {
    color: $text-color;

    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    hr {

        border-top: 1px solid $text-color;
        margin-top: 12px;
    }

    .text-content {
        margin: 24px;

        .product-logo {
            max-height: 80px;
        }
    }

    .btn-light {
        background: transparent;
        border-color: $text-color;
        color: $text-color;
        margin-top: 32px;
    }

    .btn-light:hover {
        background: rgba(255, 255, 255, 0.3);
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        .text-content .product-logo {
            max-height: 50px;
        }
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        .text-content .product-logo {
            max-height: 80px;
        }
    }


}