.dfc-what-we-do-section {
    background: #F0F0F0;

    .what-we-do-card {
        background: transparent;
    }
    
    .card-body {
        padding-top: 0.5rem !important;
    }
}