@import '../colors.scss';

.logo-title {
    margin-top: 6px;
    max-height: 28px;
    margin-right: 12px;
}

.dfc-contact-us-form {
    .row,
    .form-group
    {
        padding-top: 3px;
    }
}