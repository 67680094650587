@import "../colors.scss";

.dfc-page-section {
    text-align: center;
    padding-bottom: 64px;

    .page-section-header,
    .page-section-content {
        padding-top: 4rem;
    }

    .ps-title-highlight {
        color: $corporate-hl-color;
    }
}