@import 'common/colors.scss';

body,
html,
.dfc-app {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

a,
a:hover,
a:focus {
  color: $corporate-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $corporate-color;
}

.btn-corporate {
  color: #ffffff;
  background-color: #90112C;
  border-color: #90112C;
}

.btn-corporate:hover,
.btn-corporate:focus,
.btn-corporate:active,
.btn-corporate.active,
.open .dropdown-toggle.btn-corporate {
  color: #ffffff;
  background-color: #90112C;
  border-color: #90112C;
}

.btn-corporate:active,
.btn-corporate.active,
.open .dropdown-toggle.btn-corporate {
  background-image: none;
}

.btn-corporate .badge {
  color: #90112C;
  background-color: #ffffff;
}

.table-bordered-2x {
  border: 1px solid #000000;

  th,
  td {
    border: 1px solid #000000;

  }
}

.cursor-pointer {
  cursor: pointer;
}