@import '../colors.scss';

$navbar-opacity: 0.9;
$navbar-bg-color: #FFFFFF;

.dfc-main-navbar {
    font-weight: 600;
    font-size: 15px;

    .phone-box {
        margin-right: 10px;
    }

    .phone-icon {
        color: $corporate-color;
    }

    .navbar {
        /* The Fallback */
        background-color: $navbar-bg-color !important;

        background-color: rgba($navbar-bg-color, $navbar-opacity) !important;
        position: absolute;
        width: 100%;
        z-index: 1000;
    }

    .logo-full {
        height: 3.5rem;
        -webkit-filter: drop-shadow(2px 2px 2px #555);
        filter: drop-shadow(2px 2px 2px #555);
        display: inline-block;
    }

    @media (min-width: 576px) {
        .logo-full {
            height: 4rem;
        }
    }

    @media (min-width: 768px) {
        .logo-full {
            height: 5rem;
        }
    }

    li {
        a.nav-link {
            color: $text-color !important;
            padding: 10px 0;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
    }

    .dropdown-toggle::after {
        color: $corporate-color;
    }

    a.nav-link:hover,
    a.dropdown-item:hover,
    .nav-phone {
        color: $corporate-color !important;
    }

    a.dropdown-item:hover,
    a.dropdown-item:focus {
        background-color: $corporate-color !important;
        color: $corporate-txt-color-alt !important;
    }
}