@import '../colors.scss';

.dfc-sidebar {
    padding: 12px;
    text-align: left;
    max-width: 310px;
    border-radius: 6px;

    ul {
        margin-left: 12px;
    }

    h6 {
        font-weight: bold;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
}