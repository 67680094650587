@import "../colors.scss";

ul.timeline {
    list-style-type: none;
    position: relative;
}

ul.timeline:before {
    content: ' ';
    background: $corporate-bg-color;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

ul.timeline>li {
    margin: 20px 0;
    padding-left: 20px;
    text-align: left;
}

ul.timeline>li:before {
    content: ' ';
    background: $corporate-bg-color-light-alt;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid $corporate-bg-color;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}